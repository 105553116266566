import './css/login.css';

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useHistory} from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LogoHotExpress from './assets/HotExpress_Logo.jpg'
import LogoUnoBox from './assets/logoUnoBox.jpg'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    maxHeight: '85%',
    overflow: 'scroll',
    maxWidth: '400px',
    minWidth: '260px',
    border: '2px solid #7294c3',
    borderRadius: '10px',
    color:'#7294c3',
    boxShadow: 24,
    p: 4,
};
const Register = ({SetDataUser})=>{
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setOpenTerms(false)
        setDataRegister({name:"",lastName:"",email:"",direction:"",phone:"",celular:"",plan:""})
    };

    const[listPlans,setListPlans]=useState(false)
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        
        fetch("http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/Planes", requestOptions)
            .then(response => response.json())
            .then(result => {
                setListPlans(result.result[0].Planes)
            })
            .catch(error => console.log('error', error));
    }, [])
    let history = useHistory()
    function isMoreThan30Minutes(date1, date2) {
        const diff = Math.abs(date2 - date1);
        const minutesDiff = diff / (1000 * 60); 
            return minutesDiff > 30;
    }
    const lastSesion = localStorage.getItem('lastSesion');
    const dataUser = localStorage.getItem('dataUser'); 
    if (lastSesion && dataUser) {
        const now = new Date();
        if (isMoreThan30Minutes(new Date(lastSesion), now)) {
            //borrar los datos de localStorage
            localStorage.removeItem('dataUser');
            localStorage.removeItem('lastSesion');
        } else {
            //history.push("/traking")
        }
    } else {
      // No existen los datos en localStorageHB-4954
    }
    const [dataRegister, setDataRegister] = useState({name:"",lastName:"",email:"",direction:"",phone:"",movil:"",plan:""})
    const [checked, setChecked]=useState(false)
    const [statusRegister,setStatusRegister] = useState(null)
    const handleSendaffiliate = () => {
        console.log(dataRegister);
        validateFields();
        if(validateFields() === true){
            console.log("enviar")
        let selectedPlan = listPlans.find(item => item.PlanId === dataRegister.plan);

        var requestOptions = {
        method: "GET",
        redirect: "follow",
        };
        
        fetch(
        `http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/InsertaAfiliacion/${dataRegister.name} ${dataRegister.lastName}/${dataRegister.email}/${dataRegister.direction}/${dataRegister.phone}/${dataRegister.movil}/${dataRegister.plan}/${selectedPlan.PlanNombre}`,
        requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
            console.log(result.result[0].Afiliacion[0].Mensaje)
            let Afiliacion = result.result[0].Afiliacion[0].Mensaje
            setStatusRegister({
                Estatus: "true",
                Mensaje: `${Afiliacion}`,
            });
            handleOpen()
        })
        .catch((error) => console.log("error", error));
        }
    };

    const handleDataRegister = (e,type)=>{
        setDataRegister({
            ...dataRegister,
            [e.target.name]:e.target.value
        })
        setStatusRegister({
            Estatus: 'true',
            Mensaje: ``,
            });
    }
    const handleEnterPress = (ev)=>{
        if (ev.key === 'Enter') {
            ev.preventDefault();
            handleSendaffiliate();
        }
    }
    const validateFields = () => {
        const requiredFields = [
            { name: 'name', label: 'Nombre' },
            { name: 'lastName', label: 'Apellido' },
            { name: 'email', label: 'Correo electrónico' },
            { name: 'direction', label: 'Dirección' },
            { name: 'plan', label: 'Plan' },
        ];
        const missingFields = requiredFields.filter(field => !dataRegister[field.name]);
        if(checked === true){
        if (missingFields.length > 0) {
            const missingFieldLabels = missingFields.map(field => field.label).join(', ');
            setStatusRegister({
            Estatus: 'false',
            Mensaje: `Los siguientes campos son obligatorios: ${missingFieldLabels}`,
            });
            return false;
        }
        if (dataRegister.email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const isValidEmail = emailRegex.test(dataRegister.email);
            if (!isValidEmail) {
            setStatusRegister({ Estatus: "false", Mensaje: "El correo electrónico no tiene un formato válido" });
            return false;
            }
        }
        }else{
            setStatusRegister({ Estatus: "false", Mensaje: "Debe aceptar los términos y condiciones" });
            return false;
        }
        return true;
        }
    // console.log('dataRegister',dataRegister)
    const TerminosYCondiciones = ()=>{
        return(
            <div className="wpb_wrapper" style={{fontSize:'12px'}}>
			<p><strong>Términos y Condiciones HOT BOX:</strong></p>
            <p><strong>La membresía del servicio consistirá en el manejo, selección y distribución de la correspondencia que le llegue al suscriptor a la dirección postal o física de la ciudad de Miami, U.S.A., transportada hasta la oficina de HOT EXPRESS Panamá y entregada en el domicilio citado en este contrato.</strong></p>
            <p>SU NUMERO DE CASILLA EN U.S.A</p>
            <p>Para recibir su correspondencia y paquetería a través del HOT BOX, el suscriptor deberá informar a sus remitentes o proveedores las siguientes direcciones: (Postal o Física).</p>
            <p>&nbsp;</p>
            <p>NOMBRE DEL USUARIO (PIN #)</p>
            <p>P.O. BOX 526950</p>
            <p>MIAMI, FL. 33152-6950</p>
            <p>U.S.A.</p>
            <p>&nbsp;</p>
            <p>NOMBRE DEL USUARIO (PIN #)</p>
            <p>2292 N.W. 82 Ave.</p>
            <p>MIAMI, FL. 33198</p>
            <p>U.S.A.</p>
            <p>&nbsp;</p>
            <p><strong>FORMA DE PAGO:</strong></p>
            <p>El suscriptor se compromete a pagar a HOT EXPRESS, todas y cada una de las cuentas que le adeude, por de derechos de inscripción, mensualidades, cuotas, servicios adicionales, sobrepeso, servicios señalados, etc. El no recibir el pago por alguna de las mensualidades de afiliación será motivo de cancelación de este contrato.</p>
            <p>&nbsp;</p>
            <p>NOTA: NO ESTA PERMITIDO TRANSPORTAR JOYAS, EFECTIVO O SU EQUIVALENTE.</p>
            <p>&nbsp;</p>
            <p><strong>DERECHO:</strong></p>
            <p>Para lo no previsto en este contrato, las partes se acogen y remiten al derecho panameño y todas las diferencias o conflictos que surjan de, o en relación a este contrato, serán resueltas de acuerdo con las leyes panameñas. Ambas partes declaran que conocen y aceptan cada una de las claúsulas del presente contrato y se obligan a cumplirlas fielmente.</p>
            <p>&nbsp;</p>
            <p><strong>RESPONSABILIDAD:</strong></p>
            <p>HOT EXPRESS No es responsable por envíos en efectivo, equivalentes en efectivos o joyas.</p>
            <p>&nbsp;</p>
            <p>HOT EXPRESS Puede brindarle al cliente el servicio de “pick-up” en Miami, por lo cual cobrará la tarifa de mensajero regular.</p>

		</div>
        )
    }
    const handleChecked = (e)=>{
        setChecked(e.target.checked)
    }
    const [openTerms, setOpenTerms] = useState(false)
    const handleOpenTerms= ()=>{
        console.log('openTerms')
        setOpen(true)
        setOpenTerms(true)
    }
    const MarcaDominio = window.location.href;
    let marcaLogo = LogoHotExpress
    if(MarcaDominio.includes('1boxpanama') === true){
        marcaLogo = LogoUnoBox
    }
    return(
        <div className="container">
        <div className="screen" style={{height:'initial'}}>
            <div className="screen__content">
                <div className="login">
                    <img alt='logo' className="imgLogo" src={marcaLogo} />
                    <Box
                    id="register"
                    component="form" sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }} noValidate  autoComplete="off" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <TextField id="standard-name" label="Nombre" required variant="standard" value={dataRegister.name}  name="name" onChange={(e)=>{handleDataRegister(e, "")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <TextField id="standard-lastname" label="Apellido" required variant="standard" value={dataRegister.lastName}  name="lastName" onChange={(e)=>{handleDataRegister(e)}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <TextField id="standard-user" type ="email" required label="Correo eléctronico" variant="standard" value={dataRegister.email} name="email" onChange={(e)=>{handleDataRegister(e, "email")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <TextField id="standard-user" label="Direccíon" required variant="standard" value={dataRegister.direction} name="direction" onChange={(e)=>{handleDataRegister(e, "")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <TextField id="standard-user" type="tel" label="Telefono" variant="standard" value={dataRegister.phone} name="phone" onChange={(e)=>{handleDataRegister(e, "")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <TextField id="standard-user" type="tel" label="Celular" variant="standard" value={dataRegister.movil} name="movil" onChange={(e)=>{handleDataRegister(e, "")}} onKeyPress={(ev)=>handleEnterPress(ev)}/>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} required>
                        <InputLabel id="demo-simple-select-standard-label">Plan</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        onChange={(e)=>handleDataRegister(e)}
                        value={dataRegister.plan}
                        label="Plan"
                        name="plan"
                        >
                        <MenuItem value="">
                            <em>Elige un Plan</em>
                        </MenuItem>
                        {listPlans?listPlans.map((item,index)=>{
                            return(
                                <MenuItem key={index} value={item.PlanId}>{item.PlanDescripcion}</MenuItem>
                            )
                        }):false}
                    </Select>
                    </FormControl>
                    <FormGroup className='termsBox'>
                        <FormControlLabel id="terms" control={<Checkbox value={checked} onChange={(e)=>handleChecked(e)} />} label="Acepto los términos y condiciones del casillero virtual Hot box" />
                        <Button size='small' style={{fontSize:'10px'}} onClick={()=>handleOpenTerms()}>ver términos</Button>
                    </FormGroup>
                    <Button className=" login__submit"  onClick={()=>handleSendaffiliate()}>Afiliarme</Button>
                    <div style={{padding:'0px 30px', display:'flex', justifyContent:'center'}}>
                        <Button onClick={()=>history.push('/')}>Login</Button>
                    </div>
                    </Box>
                    {statusRegister?
                        statusRegister.Estatus === "false"?<Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert className='alerta' variant="outlined" severity="error">
                            {statusRegister.Mensaje||"El Usuario o la contraseña no son correctos"}
                        </Alert>
                    </Stack>:false
                    :false}
                </div>
                {/* <div className="social-login">
                    <h3 >Síguenos</h3>
                    <div className="social-icons">
                        <FacebookIcon  onClick={()=>{window.open("https://www.facebook.com/HotExpressPanama/", "_blank")}}/>
                        <InstagramIcon  onClick={()=>{window.open("https://www.instagram.com/hotexpresspanama/", "_blank")}}/>
                        <YouTubeIcon  onClick={()=>{window.open("https://www.youtube.com/channel/UCPSdMYC2fc20LBEom3uYtyg", "_blank")}}/>
                    </div>
                </div> */}
            </div>
            <div className="screen__background">
                <span className="screen__background__shape screen__background__shape4"></span>
                <span className="screen__background__shape screen__background__shape3"></span>
                <span className="screen__background__shape screen__background__shape2"></span>
                <span className="screen__background__shape screen__background__shape1"></span>
            </div>
        </div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Button className='closeBottom' onClick={handleClose}>X</Button>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
            </Typography> */}
            {openTerms? <TerminosYCondiciones/>:
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {statusRegister?statusRegister.Mensaje:null}
            </Typography>}
        </Box>
        </Modal>
    </div>
    )
}

export default Register;