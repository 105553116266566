import React, { useState, useEffect }  from 'react'
import Box from '@mui/material/Box';
import '../css/home.css';
import Header from './Header';
import Grid from '@mui/material/Grid';
import {useHistory} from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import LogoHotExpress from '../assets/HotExpress_Logo.jpg'
import LogoUnoBox from '../assets/logoUnoBox.jpg'


const History = ({dataUser})=>{
    let history = useHistory()
    const [loading,SetLoading] = useState(true)
    if (dataUser) {
        const lastSesionTime = new Date()
        localStorage.setItem("dataUser", JSON.stringify(dataUser));
        localStorage.setItem("lastSesion", lastSesionTime);
    }else{
        function isMoreThan30Minutes(date1, date2) {
            const diff = Math.abs(date2 - date1);
            const minutesDiff = diff / (1000 * 60); 
                return minutesDiff > 30;
        }
        const lastSesion = localStorage.getItem('lastSesion');
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        //console.log(dataUserLocal)
        if (lastSesion && dataUserLocal) {
            const now = new Date();
            if (isMoreThan30Minutes(new Date(lastSesion), now)) {
                localStorage.removeItem('dataUser');
                localStorage.removeItem('lastSesion');
                history.push("/")
            }
        }else{
            history.push("/")
        }
    }

    const [planInfo,SetPlanInfo] = useState({
        "PinNo": "HB-4954",
        "NombreCliente": "FERNANDO LOZA",
        "IdPlan": "2",
        "NombrePlan": "",
        "LibrasPlan": "0.000",
        "LibrasConsumidas": "0.000",
        "LibrasExcedentes": "0.000",
        "ValorExcedentes": "0.000"
    })
    console.log(planInfo)
    const [balanceLibras,SetBalanceLibras] = useState(false)
    //devuelve la fecha basado en el primer dia de el mes actual
    const getFirstDay = () => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        //devuelve firstDay en formato mes/dia/año ejemplo: 03-13-2023
        return `${firstDay.getMonth()+1}-${firstDay.getDate()}-${firstDay.getFullYear()}`
    }
    let hoy = new Date()
    hoy = `${hoy.getMonth()+1}-${hoy.getDate()}-${hoy.getFullYear()}`
    const [fechaFilter,SetFechaFilter] = useState({"inicial":getFirstDay(),"final":hoy})
    useEffect(() => {
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/InformacionPlan/${dataUserLocal.PinNo}`, requestOptions)
            .then(response => response.json())
            .then(result =>{
                //console.log(result)
                const infoPlan = result.result[0].InfoPlan[0];
                //console.log(infoPlan)
                SetPlanInfo(infoPlan)
            })
            .catch(error => console.log('error', error));

            var requestOptions2 = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/HistorialCliente/${dataUserLocal.PinNo}/${fechaFilter.inicial}/${fechaFilter.final}`, requestOptions2)
                .then(response => response.json())
                .then(result => {
                    const historyClientData = result.result[0].Guias;
                    const setDataLibras = historyClientData.map((item,index) => ({
                        id: index,
                        PaqueteNo: item.Ciupa,
                        Fecha: item.Fecha,
                        CienteRemitente: item.Remitente,
                        Peso: item.Peso,
                        Valor: item.Valor
                    }))
                    //console.log("balanceLibrasData",JSON.stringify(setDataLibras))
                    SetBalanceLibras(setDataLibras)
                    SetLoading(false)
                })
                .catch(error => console.log('error', error));
    },[])
    const handleChangeDate =()=>{
        var requestOptions2 = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/HistorialCliente/${dataUserLocal.PinNo}/${fechaFilter.inicial}/${fechaFilter.final}`, requestOptions2)
                .then(response => response.json())
                .then(result => {
                    const historyClientData = result.result[0].Guias;
                    const setDataLibras = historyClientData.map((item,index) => ({
                        id: index,
                        PaqueteNo: item.Ciupa,
                        Fecha: item.Fecha,
                        CienteRemitente: item.Remitente,
                        Peso: item.Peso,
                        Valor: item.Valor
                    }))
                    //console.log("balanceLibrasData",JSON.stringify(setDataLibras))
                    SetBalanceLibras(setDataLibras)
                    SetLoading(false)
                })
                .catch(error => console.log('error', error));
    }
    const handleChangeInitialDate = (e)=>{
        console.log(new Date(e.$d))
        let initialDate =  new Date(e.$d)
        const inicial = `${initialDate.getMonth()+1}-${initialDate.getDate()}-${initialDate.getFullYear()}`
        SetFechaFilter({...fechaFilter, inicial: inicial})
    }
    const handleChangeFinalDate = (e)=>{
        console.log(new Date(e.$d))
        let initialDate =  new Date(e.$d)
        const inicial = `${initialDate.getMonth()+1}-${initialDate.getDate()}-${initialDate.getFullYear()}`
        SetFechaFilter({...fechaFilter, final: inicial})
    }
    const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
    const MarcaDominio = window.location.href;
    let marcaLogo = LogoHotExpress
    if(MarcaDominio.includes('1boxpanama') === true){
        marcaLogo = LogoUnoBox
    }
    return(
        <div style={{position:'relative'}}>
            <Header history={history} dataUser={dataUserLocal}/>
            <div className='infoDetailPackage topMargin' >
                <Box sx={{ width: { xs: '95%', sm: '80%' }, textAlign:'center', display:'grid', justifyContent:'space-around' }}>
                    <img alt='logo' style={{width:'200px', justifySelf:'center'}} src={marcaLogo} />
                    <h2 className='TitleBalance'>Historial de Paquetes</h2>
                    <Grid container spacing={2}  alignItems="stretch" direction="row" justifyContent="center">
                        <Grid item xs={12} sm={5} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer
                                    components={[
                                    'MobileDatePicker'
                                    ]}
                                >
                                    <DemoItem label="Fecha Inicial">
                                        <MobileDatePicker className='FechaInicial' onChange={(e)=>handleChangeInitialDate(e)} defaultValue={dayjs(fechaFilter.inicial)} maxDate={dayjs(hoy)}/>
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer
                                    components={[
                                    'MobileDatePicker'
                                    ]}
                                >
                                    <DemoItem label="Fecha Inicial">
                                        <MobileDatePicker className='FechaInicial' onChange={(e)=>handleChangeFinalDate(e)} style={{height:'43px'}} defaultValue={dayjs(fechaFilter.final)} maxDate={dayjs(hoy)} />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ display: { xs: 'flex', sm: 'none' } }} style={{alignItems:'end', display:'flex', justifyContent:'center'}}>
                            <Button variant="contained" size="large" onClick={handleChangeDate}>Buscar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className='infoUserList' style={{ background:'#F9F9F9'}}>
                <Box sx={{ width: '80%',display:'grid',gap:'10px' }}>
                {balanceLibras ?
                    <div className="table-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>Paquetes No.</th>
                                <th>Fecha</th>
                                <th>Remitente</th>
                                <th>Peso / <span style={{fontSize:'12px'}}>lbs</span></th>
                                <th>Valor </th>
                            </tr>
                            </thead>
                            <tbody>
                            {balanceLibras.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td data-label="Paquetes No." className='numPackage'>{item.PaqueteNo}</td>
                                        <td data-label="Fecha">{item.Fecha}</td>
                                        <td data-label="Remitente">{item.CienteRemitente}</td>
                                        <td data-label="Peso / lbs">{(+(item.Peso)).toFixed(2)}</td>
                                        <td data-label="Valor">${(+(item.Valor)).toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    :
                    loading?
                        <>
                            <Skeleton style={{borderRadius:'25px'}} animation="wave" height={30} />
                            <Skeleton style={{borderRadius:'25px'}} animation="wave" height={30} />
                            <Skeleton style={{borderRadius:'25px'}} animation="wave" height={30} />
                        </>
                        :
                        <div>No hay guías disponibles</div>
                }
                </Box>
            </div>
        </div>
    )
}
export default History;