import React, { useState }  from 'react'

import Box from '@mui/material/Box';

import '../css/home.css';
import Header from './Header';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import {useHistory} from 'react-router-dom';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import IconButton from '@mui/material/IconButton';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
const History = ({dataUser})=>{
    let history = useHistory()

    if (dataUser) {
        const lastSesionTime = new Date()
        localStorage.setItem("dataUser", JSON.stringify(dataUser));
        localStorage.setItem("lastSesion", lastSesionTime);
    }else{
        function isMoreThan30Minutes(date1, date2) {
            const diff = Math.abs(date2 - date1);
            const minutesDiff = diff / (1000 * 60); 
                return minutesDiff > 30;
        }
        const lastSesion = localStorage.getItem('lastSesion');
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        //console.log(dataUserLocal)
        if (lastSesion && dataUserLocal) {
            const now = new Date();
            if (isMoreThan30Minutes(new Date(lastSesion), now)) {
                localStorage.removeItem('dataUser');
                localStorage.removeItem('lastSesion');
                history.push("/")
            }
        }else{
            history.push("/")
        }
    }

    const initialPackage =
        {"Paquete":"",
        "Destinatario":"",
        "Remitente":"",
        "Trackings":"[]",
        "Cantidad":"0",
        "Peso":"0.000",
        "Estado":"",
        "Fecha":""
    }
    const [packageInfo,SetPackageInfo] = useState(initialPackage)
    const [expanded, setExpanded] = React.useState(false);
    const [numPackage,SetNumPackage] = React.useState('')
    const [packageExist, SetPackageExist] = React.useState(true);
    const handleChangePackage =()=>{
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/RastreaPaquete/${dataUserLocal.PinNo}/${numPackage}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                const data = result.result[0].RastreoPaquete;
                console.log('find', data.length)
                if(data.length > 0){
                    SetPackageInfo(data[0])
                    SetPackageExist(true)
                }else{
                    SetPackageInfo(initialPackage)
                    SetPackageExist(false)
                }
            })
            .catch(error => console.log('error', error));
    }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleTypePackage = (e)=>{
    SetNumPackage(e.target.value)
  }
  const convertToJson=(trackins)=>{
    const data = JSON.parse(trackins)
    return data
    }
    const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));

    const findColor = (Estatus)=>{
        //Recibido Hot Miami, En Transito, Recibido en Tocumen, Recibido en Oficina, Recibido en Sucursal, En Ruta de Entrega y Entregado
        switch (Estatus) {
            case 'Recibido Hot Miami':
                return '#282A6F'
            case 'En Transito':
                return '#838383'
            case 'Recibido en Tocumen':
                return '#D96D38'
            case 'Recibido en Oficina':
                return '#D96D38'
            case 'Recibido en Sucursal':
                return '#D96D38'
            case 'En Ruta de Entrega':
                return '#838383'
            case 'Entregado':
                return '#286f42'
            default:
                return '#838383'
        }
    }
  const handleCleanPackageNumber = () => {
    SetNumPackage('')
  };
    return(
        <div style={{position:'relative'}}>
            <Header history={history} dataUser={dataUserLocal}/>
            <div className='infoDetailPackage topMargin' >
                <Box sx={{ width: '80%', textAlign:'center', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-around' }}>
                    <img alt='logo' style={{width:'200px', justifySelf:'center'}} src="https://hotexpresspanama.com/wp-content/uploads/2022/08/HotExpress_Logo.png" />
                    <h2 className='TitleBalance'>Rastreo de Paquetes</h2>
                    <Grid container spacing={2}  alignItems="stretch" direction="row" justifyContent="center">
                        <Grid item xs={12} sm={6} >
                        <FormControl fullWidth variant="outlined" size="small" >
                            <InputLabel htmlFor="outlined-adornment-password">Número de Paquete ó tracking</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type="text"
                                value={numPackage}
                                onChange={(e)=>handleTypePackage(e)}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleCleanPackageNumber}
                                    edge="end"
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Número de Paquete ó tracking"
                            />
                            </FormControl>
                        </Grid>
                        <Grid  item xs={12} sm={4} sx={{ display: { xs: 'flex', sm: 'none' } }} style={{alignItems:'end', display:'flex', justifyContent:'center'}}>
                            <Button style={{width:'100%'}} variant="contained" size="large" onClick={handleChangePackage}>Buscar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className='infoUserList' style={{ background:'#F9F9F9'}}>
            {
                packageExist?
                packageInfo.Paquete !== ""?
                <Box sx={{ width: {xs:'80%',sm:'50%',lg:'40%'},display:'grid',gap:'10px' }}>
                    <div className='itemListPackage' >
                        <div className='itemListLeftPackage'>
                            <div style={{color:'#707070', display:'grid'}}>
                                <span style={{color:'#333'}}>Remitente:</span>
                                <span>{packageInfo.Remitente}</span>
                            </div>
                            <div style={{color:'#707070', display:'grid'}}>
                                <span style={{color:'#333'}}>Destinatario:</span>
                                <span>{packageInfo.Destinatario}</span>
                            </div>
                            <div style={{color:'#707070', display:'grid'}}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{boxShadow:'none', margin:'0px', padding:'5px',backgroundColor:'#ededed'}}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    style={{padding:'0px',margin:'0px', minHeight:'initial'}}
                                    >
                                    <span style={{color:'#333'}}>Trackings: piezas ({packageInfo.Trackings !== undefined?convertToJson(`${packageInfo.Trackings}`).length:0})</span>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    {packageInfo.Trackings !== undefined?convertToJson(`${packageInfo.Trackings}`).map((item,index)=>{
                                        return(
                                            <Chip key={index} label={item} />
                                        )
                                    }):null}
                                    </AccordionDetails>
                                </Accordion>
                                <span></span>
                            </div>
                            <div style={{color:'#707070', display:'grid'}}>
                                <span style={{color:'#333'}}>Paquetes:</span>
                                <span>{packageInfo.Paquete}</span>
                            </div>
                            <div style={{color:'#707070', display:'grid'}}>
                                <span style={{color:'#333'}}>Peso:</span>
                                <span>{(+(packageInfo.Peso)).toFixed(2)}</span>
                            </div>
                            <div style={{color:'#707070', display:'grid'}}>
                                <span style={{color:'#333'}}>Estado:</span>
                                <strong style={{color:`${findColor(`${packageInfo.Estado}`)}`}}>{packageInfo.Estado}</strong>
                                <span>{packageInfo.Fecha}</span>
                            </div>
                        </div>
                    </div>
                </Box>:null:<Alert className='alerta' variant="outlined" severity="error">
                            El número de Paquete ó tracking son incorrectos
                        </Alert>}
            </div>
        </div>
    )
}
export default History;