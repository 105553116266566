import React, { useState, useEffect }  from 'react'

import Box from '@mui/material/Box';

import '../css/home.css';
import Header from './Header';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LogoHotExpress from '../assets/HotExpress_Logo.jpg'
import LogoUnoBox from '../assets/logoUnoBox.jpg'
import Balance from '../assets/balance.png'

import {useHistory} from 'react-router-dom';

import Skeleton from '@mui/material/Skeleton';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    borderRadius:'10px',
    minHeight:'60px',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }));

const BalanceComp = ({dataUser})=>{
    let history = useHistory()
    const [scrollPosition, setScrollPosition] = useState(0);
    const [loading,SetLoading] = useState(true)
    if (dataUser) {
        const lastSesionTime = new Date()
        localStorage.setItem("dataUser", JSON.stringify(dataUser));
        localStorage.setItem("lastSesion", lastSesionTime);
    }else{
        function isMoreThan30Minutes(date1, date2) {
            const diff = Math.abs(date2 - date1);
            const minutesDiff = diff / (1000 * 60); 
                return minutesDiff > 30;
        }
        const lastSesion = localStorage.getItem('lastSesion');
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        //console.log(dataUserLocal)
        if (lastSesion && dataUserLocal) {
            const now = new Date();
            if (isMoreThan30Minutes(new Date(lastSesion), now)) {
                localStorage.removeItem('dataUser');
                localStorage.removeItem('lastSesion');
                history.push("/")
            }
        }else{
            history.push("/")
        }
    }
    useEffect(() => {
    function handleScroll() {
        const currentPosition = window.scrollY;
        setScrollPosition(currentPosition);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, []);
    const [planInfo,SetPlanInfo] = useState({
        "PinNo": "HB-4954",
        "NombreCliente": "FERNANDO LOZA",
        "IdPlan": "2",
        "NombrePlan": "",
        "LibrasPlan": "0.000",
        "LibrasConsumidas": "0.000",
        "LibrasExcedentes": "0.000",
        "ValorExcedentes": "0.000"
    })
    const [balanceLibras,SetBalanceLibras] = useState(false)
    useEffect(() => {
        const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/InformacionPlan/${dataUserLocal.PinNo}`, requestOptions)
            .then(response => response.json())
            .then(result =>{
                //console.log(result)
                const infoPlan = result.result[0].InfoPlan[0];
                //console.log(infoPlan)
                SetPlanInfo(infoPlan)
            })
            .catch(error => console.log('error', error));

            var requestOptions2 = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`http://172.200.203.155:1028/datasnap/rest/TApiHotExpress/BalanceLibras/${dataUserLocal.PinNo}`, requestOptions2)
                .then(response => response.json())
                .then(result => {
                    const balanceLibrasData = result.result[0].BalanceLibras;
                    const setDataLibras = balanceLibrasData.map((item,index) => ({
                        id: index,
                        PaqueteNo: item.CiupaId,
                        Fecha: item.FechaCiupa,
                        CienteRemitente: item.ClienteRemitente,
                        Peso: item.Peso,
                        Balance: item.Balance
                    }))
                    //console.log("balanceLibrasData",JSON.stringify(setDataLibras))
                    SetBalanceLibras(setDataLibras)
                    SetLoading(false)
                })
                .catch(error => console.log('error', error));
    },[])

    const dataUserLocal = JSON.parse(localStorage.getItem('dataUser'));
    const MarcaDominio = window.location.href;
    let marcaLogo = LogoHotExpress
    if(MarcaDominio.includes('1boxpanama') === true){
        marcaLogo = LogoUnoBox
    }
    return(
        <div style={{position:'relative'}}>
            <Header history={history} dataUser={dataUserLocal}/>
            <div className='infoDetailPackage topMargin' >
                <Box sx={{ width: { xs: '95%', sm: '80%' }, textAlign:'center' }}>
                    <img alt='logo' style={{width:'200px'}} src={marcaLogo} />
                    <h2 className='TitleBalance'>Balance de Libras</h2>
                    <Grid container spacing={2} alignItems="stretch" direction="row" justifyContent="center">
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Item >
                                <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%'}}>
                                    {scrollPosition < 55?
                                    <div className='circleIconBalanceMin'>
                                        <img alt='icon' src={Balance} className="iconImage"/>
                                    </div>
                                    :`${planInfo.NombrePlan}`}
                                    <div className='textItemBox' style={{display: 'flex',alignItems: 'center'}}><span style={{fontSize:'10px',color:'gray', fontWeight: '500'}}>Libras Disponibles:</span>{(+(planInfo.LibrasPlan)-+(planInfo.LibrasConsumidas))}<span style={{fontSize:'18px'}}>lbs</span></div>
                                </div>
                                <div className='titleBox' style={{borderTop:"3px solid #53A551"}}>{scrollPosition > 55?null:<>{planInfo.NombrePlan} <strong>{(+(planInfo.LibrasPlan)).toFixed(2)} lbs</strong></>}</div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className='infoUserList' style={{ background:'#F9F9F9'}}>
                <Box sx={{ width: '80%',display:'grid',gap:'10px' }}>
                {balanceLibras ?
                    <div className="table-wrapper">
                        <table>
                            <thead>
                            <tr>
                                <th>Paquetes No.</th>
                                <th>Fecha</th>
                                <th>Remitente</th>
                                <th>Peso / <span style={{fontSize:'12px'}}>lbs</span></th>
                                <th>Balance / <span style={{fontSize:'12px'}}>lbs</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            {balanceLibras.map((item,index)=>{
                                return(
                                    <tr key={index}>
                                        <td data-label="Paquetes No." className='numPackage'>{item.PaqueteNo}</td>
                                        <td data-label="Fecha">{item.Fecha}</td>
                                        <td data-label="Remitente">{item.CienteRemitente}</td>
                                        <td data-label="Peso / lbs">{(+(item.Peso)).toFixed(2)}</td>
                                        <td data-label="Balance / lbs">{(+(item.Balance)).toFixed(2)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    :
                    loading?
                        <>
                            <Skeleton style={{borderRadius:'25px'}} animation="wave" height={30} />
                            <Skeleton style={{borderRadius:'25px'}} animation="wave" height={30} />
                            <Skeleton style={{borderRadius:'25px'}} animation="wave" height={30} />
                        </>
                        :
                        <div>No hay guías disponibles</div>
                }
                </Box>
            </div>
        </div>
    )
}
export default BalanceComp;